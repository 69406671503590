import * as React from "react"

const Header = () => {
  return (
    <div className="p-1.5 bg-red-800">
        <h2 
            className="text-gray-100 text-sm text-center tracking-tighter uppercase font-semibold">
            Kastle 
            <span className="text-white font-bold">
                Restore
            </span>
        </h2>
    </div>
  )
}

export default Header;