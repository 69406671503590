import * as React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";


import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon, PhoneIcon } from "@heroicons/react/outline";

import "./styles.css";


export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-gray-100">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-center h-16">
              <div className="flex w-full">
                <div className="flex-shrink-0 flex items-center">
                  <AnchorLink
                      to="#heroSection"
                      className="flex items-center">
                    <StaticImage 
                        className="w-8"
                        src="../../images/Logo.png" 
                        alt="Example of brick wall that was built recently."
                    />
                      <h2 className="text-gray-900 hover:text-gray-700 text-2xl font-semibold uppercase">astle Restore</h2>
                  </AnchorLink>
                </div>
                <div className="hidden md:mr-32 md:-ml-6 lg:ml-0 lg:mr-0 md:flex sm:space-x-6 lg:space-x-8 justify-center w-full">
                  <AnchorLink 
                    to="#aboutSection"
                    className="transition ease-in-out delay-50 duration-300 border-transparent text-gray-800 hover:text-gray-500 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-semibold"
                  >
                    About
                  </AnchorLink >
                  <AnchorLink
                    to="#serviceSection"
                    className="transition ease-in-out delay-50 duration-300 border-transparent text-gray-800 hover:text-gray-500 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-semibold"
                  >
                    Services
                  </AnchorLink>
                  <AnchorLink
                    to="#contactSection"
                    className="transition ease-in-out delay-50 duration-300 border-transparent text-gray-800 hover:border-forestGreen hover:text-gray-500 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-semibold"
                  >
                    Contact
                  </AnchorLink>
                  <AnchorLink
                    to="#projectSection"
                    className="transition ease-in-out delay-50 duration-300 border-transparent text-gray-800 hover:text-gray-500 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-semibold"
                  >
                    Projects
                  </AnchorLink>
                </div>
              </div>
              <div className="hidden md:ml-6 md:flex md:items-center md:space-x-2">
                  <a href="https://www.facebook.com/KastleLLC" className="transition ease-in-out delay-50 duration-300 text-xl text-gray-700 hover:text-gray-500 pr-1">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      aria-hidden="true"
                    >
                      <path
                        d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                        fill="currentColor"
                      />
                    </svg>
                </a>
                <dd className="text-base text-gray-900 hidden lg:flex items-center">
                  <PhoneIcon className="flex-shrink-0 w-6 h-6 text-gray-900" aria-hidden="true" />
                  <span className="w-max">+1 (773) 592-8602</span>
                </dd>
              </div>


              <div className="-mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="transition ease-in-out delay-50 duration-300 inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-100">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="transition ease-in-out delay-50 duration-300 md:hidden shadow-md">
            <div className="pt-2 pb-3 text-left">
              <AnchorLink to="#aboutSection">
                <Disclosure.Button
                  as="AnchorLink"
                  className="transition ease-in-out delay-50 duration-300 text-gray-800 hover:bg-gray-200 hover:text-gray-700 block pl-4 pr-4 py-2 border-l-4 text-xl"
                >
                  About
                </Disclosure.Button>
              </AnchorLink>

              <AnchorLink to="#serviceSection">
                <Disclosure.Button
                  as="a"
                  className="transition ease-in-out delay-50 duration-300 text-gray-800 hover:bg-gray-200 hover:text-gray-700 block pl-4 pr-4 py-2 border-l-4 text-xl"
                >
                  Services
                </Disclosure.Button>
              </AnchorLink>

              <AnchorLink to="#contactSection">
                <Disclosure.Button
                  as="a"
                  className="transition ease-in-out delay-50 duration-300 text-gray-800 hover:bg-gray-200 hover:text-gray-700 block pl-4 pr-4 py-2 border-l-4 text-xl"
                >
                  Contact
                </Disclosure.Button>
              </AnchorLink>
              <AnchorLink to="#projectSection">
                <Disclosure.Button
                  as="a"
                  className="transition ease-in-out delay-50 duration-300 text-gray-800 hover:bg-gray-200 hover:text-gray-700 block pl-4 pr-4 py-2 border-l-4 text-xl"
                >
                  Projects
                </Disclosure.Button>
              </AnchorLink>
            </div>


            <div className="pt-4 pb-3">
                <div className="md:hidden ml-2 flex items-center space-x-2">
                  <div className="md:ml-6 ml-2 flex md:items-center space-x-2 text-md pb-2">
                    <h2 className="">You can give us a call at +1 (773) 592-8602 or reach us on Facebook. 
                        <Link to="https://www.facebook.com/KastleLLC" className="inline-block ml-2 -mb-1 transition ease-in-out delay-50 duration-300 text-xl text-gray-700 hover:text-gray-500 pr-1">
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            aria-hidden="true"
                          >
                            <path
                              d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                              fill="currentColor"
                            />
                          </svg>
                        </Link>
                    </h2>
                    
                  </div>
                </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}